import React, { useState } from 'react';
//import { Link } from 'react-router-dom';
import './SearchResultsPage.css';
import { useNavigate } from 'react-router-dom';
import Cards from './Cards';


function SearchResultsPage({ query, setQuery }) {

    const [inputValue, setInputValue] = useState(query); // Local state to handle input
    const navigate = useNavigate();

    const handleInputChange = (event) => {
        setInputValue(event.target.value); // Update local input state
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        setQuery(inputValue); // Update the global query on form submission
        navigate('/results'); // Stay on the same page
    };

    return (
        <div>
            <div class="container-search"> {/*row to show icon/search/filters*/}
                <div class="icon-container">
                    icon
                </div>
                <div>
                    <form class="search-div" onSubmit={handleSubmit}>
                    <input 
                        class="search-bar-search"
                        type="text"
                        value={inputValue}
                        onChange={handleInputChange}
                        placeholder={query}
                    />
                    </form>
                </div>
                <div>
                    <select class="filter1-properties-search" id="result-type" name="result-type">
                        <option value="sold">Sold</option>
                    </select>
                    <select class="filter2-properties-search" id="category" name="category">
                        <option value="Cards">Cards</option>
                    </select>
                    <select class="filter3-properties-search"  id="sort" name="sort">
                        <option value="high">Highest Price</option>
                    </select>
                </div>
            </div>

            <div class="row-under-search">
            {query ? (
                <div>
                    <p>Showing results for: <strong>{query}</strong></p>
                    {/* Add logic to display actual search results here */}
                <div class="results-view">
                <Cards />
    
                   
                </div>
            </div>
            ) : (
                <p>No search term provided.</p>
            )}
            </div>

         
           {/* <Link to="/">
                <button style={{ padding: '10px 20px', fontSize: '16px' }}>Back to Search</button>
            </Link>*/}

        </div>
    );
}

export default SearchResultsPage;